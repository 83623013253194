<template>
    <main id="liste" >
        <HeaderTab 
            :title="$t('payment.my_payments')" 
        />

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="box">
                            <CustomTable
                                id_table="addressed_payment"
                                :checkboxes="false"
                                :items="items_paiements"
                                :busy.sync="working"
                                :sortDesc="false"
                                sortBy="duedate_date"
                                primaryKey="duedate_id"
                                :hasExportOption="false"
                                :showPagination="false"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script type="text/javascript">
    import Invoice from '@/mixins/Invoice.js'
    import Navigation from '@/mixins/Navigation.js'

	export default {
        name: "PayementsAddressed",
        mixins:[Invoice, Navigation],
		data () {
			return {
                items_paiements: null,
                working: false,

                events_tab: {
                    "TableAction::goToPdfInvoice": (params) => {
                        this.getAddressedPdfInvoice(params.invoice_ids, params.invoice_nums)
                    }
                }
			}
		},

		mounted() {
            this.init_component()
		},

		methods: {
            async init_component() {
                this.working = true

                this.getAddressedPayments()
                .then(r => {
                    this.items_paiements = r
                })
                .catch(e => {
                    this.failureToast("toast.info_lecture_failed")
                    console.error("InvoiceAddressed::init_component => Error:", e)
                })
                .finally(() => {
                    this.working = false
                })                
            }
        },

		components: {
            HeaderTab: () => import('GroomyRoot/components/HeaderTab'),
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
		}
	}
</script>
